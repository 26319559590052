export const CUSTOM_PAGE_WIDGET_TYPES = {
  creditScore: 'credit-score',
  nzCreditScore: 'nz-credit-score',
  rentReporting: 'rent-reporting',
  myPlan: 'my-plan',
  mortgage: 'mortgage',
  whatIsWithinReach: 'what-is-within-reach',
  mortgageEligibility: 'mortgage-eligibility',
  mortgageDIP: 'mortgage-DIP',
  depositBuilder: 'deposit-builder',
  propertyGoal: 'property-goal',
  vmMyPlan: 'vm-plan',
  vmPoints: 'vm-points',
  vmWidgetAward: 'vm-widget-award',
  partnersEligibilityCheck: 'partners-eligibility-check',
  pensionFinder: 'pension-finder',
  ffGuidance: 'ff-guidance',
  ffFlowPlayback: 'ff-flow-playback',
  ffChecklist: 'ff-checklist',
  mabPropertyPortfolio: 'mab-property-portfolio',
  mabImportPortfolio: 'mab-import-portfolio',
  mabAffordability: 'mab-affordability',
  mortgagePassport: 'mortgage-passport',
  accordionResult: 'accordion-result',
  climatePersona: 'climate-persona',
  footprintCalculator: 'footprint-calculator',
  iframe: 'iframe',
  vmBFBrighterFutureSurvey: 'vm-bf-brighter-future-survey',
  vmBFInvestorQuiz: 'vm-bf-investor-quiz',
  vmBFInflationPoll: 'vm-bf-inflation-poll',
  universitySavingsCalculator: 'university-savings-calculator',
  budgetingTool: 'budgeting-tool',
  downsizingAssessment: 'downsizing-assessment'
};
