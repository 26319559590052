import { compose, lifecycle, withContext, withHandlers, withState } from 'recompose';
import { users } from '@apps/firsthomecoach/src/api/users';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFAQ } from '@apps/firsthomecoach/src/store/reducers/faq';
import { withLoadingHandlers } from '@apps/firsthomecoach/src/components/with-loader';
import { withRouter } from 'react-router-dom';
import { createCustomPageProps } from '@apps/firsthomecoach/src/helpers/utils';
import { CUSTOM_PAGE_WIDGET_TYPES } from '@apps/firsthomecoach/src/helpers/custom-page/constants';
import { DONE, RESULT } from '@apps/firsthomecoach/src/helpers/navigation';
import { withLoader } from '../../../components/template';
import { getUserAttributes } from '../../../store/reducers/user-attributes/actions';

export const withCustomPageContext = compose(
  withRouter,
  connect(
    ({ content }, { location }) => {
      const { full } = createCustomPageProps(location.pathname);
      const widgetName = content?.customPages?.[full]?.widget?.name || null;
      return { widgetName };
    },
    { getFAQ, getUserAttributes }
  ),
  withState('customPageState', 'setCustomPageState', {}),
  withLoadingHandlers(false),
  withHandlers({
    updateCustomPageState:
      ({ setCustomPageState }) =>
      (name, value) => {
        setCustomPageState(prevState => ({ ...prevState, [name]: value }));
      }
  }),
  withHandlers({
    handleDownsizingAssessment:
      ({ updateCustomPageState, getFAQ, getUserAttributes }) =>
      async widgetName => {
        const [response] = await Promise.all([
          users.getStayOrMoveResult(),
          getFAQ({ name: 'FAQBreakdown', normalize: 'key' })
        ]);
        await getUserAttributes();
        updateCustomPageState(widgetName, response);
      }
  }),
  withHandlers({
    handleWidget:
      ({ handleDownsizingAssessment, turnOffLoading, turnOnLoading }) =>
      async widgetName => {
        const widgetHandlers = {
          [CUSTOM_PAGE_WIDGET_TYPES.downsizingAssessment]: handleDownsizingAssessment
        };

        if (!widgetHandlers?.[widgetName]) {
          return;
        }
        try {
          turnOnLoading();
          await widgetHandlers[widgetName](widgetName);
        } finally {
          turnOffLoading();
        }
      }
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { handleWidget, customPageState, widgetName, setCustomPageState } = this.props;

      const oldCustomPageProps = createCustomPageProps(prevProps.location.pathname);
      const newCustomPageProps = createCustomPageProps(this.props.location.pathname);

      // Clean up the state when the goes to RESULT page from DONE page, DONE is triggered when you update the flow from begining
      if (oldCustomPageProps.trigger === DONE && newCustomPageProps.trigger === RESULT) {
        setCustomPageState({});
        return;
      }

      // Run function only if we don't have the state for the specific widget name, if we have we don't run func again and take it from cash
      if (widgetName && !customPageState[widgetName] && !this.props.loading) {
        await handleWidget(widgetName);
      }
    }
  }),
  withContext({ customPageContext: PropTypes.object }, ({ customPageState, widgetName }) => {
    return {
      customPageContext: {
        customPageWidgetState: customPageState?.[widgetName]
      }
    };
  }),
  withLoader
);
